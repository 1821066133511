<template>
  <div class="Site PageBox">
    <NavBar :title="$t('hall.default[3]')" :page-number="-1"/>
    <div class="ScrollBox">
      <div class="header0">
          <div class="MyHeader2">
              <img class="head" :src="UserInfo.header.includes('head_')?`./static/head/${UserInfo.header}`:UserInfo.header" />
          </div>
          <div>{{ UserInfo.username}}</div>
      </div>
      <div class="infoBox border-grey" style="padding: 20px; line-height: 25px">
          <div>
<!--              <div style="font-size:16px;color: var(&#45;&#45;font_color)">{{ UserInfo.username.substring(0,15) }}</div>-->
              <div>{{$t('bindAccount.fields[0]')}} :{{CardInfo.name}}</div>
          </div>
          <div>
              <div style="font-size:15px; margin: 6px 0 6px;"> {{ $t("hall.default[1]")}} USDT:
                  <span style="color:var(--bc-text-color-0);font-weight: bold">{{ parseFloat(UserInfo.balance).toFixed(2)}}</span></div>
              <div>{{$t('buy.label[2]')}} :{{CardInfo.card_no}}</div>
          </div>
      </div>
      <div class="infoBox gradient-panel">
        <div style="display: flex;padding: 15px">
          <div style="line-height: 25px;width: 50%">
            <div style="font-size: 17px;font-weight: bold">{{parseFloat(CoinInfo.price).toFixed(2)}}</div>
            <div style="font-size: 13px;">{{$t('sell.label[3]')}}</div>
            <div style="font-size: 13px; color: var(--bc-text-color)">USDT</div>
          </div>
          <div class="shu" style="height: 78px; background-color: var(--bc-text-color) "></div>
          <div style="width: 20%"></div>
          <div style="line-height: 25px;width: 30%">
            <div>
              <div style="font-size: 17px;font-weight: bold">{{parseFloat(CoinInfo.bestPrice).toFixed(2)}}</div>
              <div style="font-size: 13px;">{{$t('sell.label[4]')}}</div>
              <div style="font-size: 13px; color: var(--font_subtitle)">USDT</div>
            </div>
          </div>
        </div>
      </div>
      <div class="infoBox border-grey">

        <h3>
          {{$t('sell.label[5]')}}
        </h3>
          <div>
            <van-field readonly class="input" :border="false"
                       v-model="BankInfo.price"
                       :placeholder="$t('sell.placeholder[0]')"
            />
            <div style="margin-top: 12px;">
              <van-button size="min"
                          style="background-color:var(--bc-text-color-4);
                        width: 170px;height: 35px;
                        margin-left: 120px;font-size: 13px;
                        color: var(--main-bg-color);"
                          @click="setPrice">
                <span style="font-size: 14px">{{$t('sell.label[7]')}}</span></van-button>
            </div>
          </div>
        <h3>
          {{$t('sell.label[6]')}}
        </h3>
          <div>
          <van-field class="input" :border="false"
                     v-model="BankInfo.total_number"
                  :placeholder="$t('sell.placeholder[1]')"
          />
            <van-button size="min" style="width: 100px;height: 30px;margin-left: 120px;background-color:var(--bc-text-color-5)" @click="setAmount">
              <span style="color:#FFF;font-size: 14px">{{$t('sell.label[8]')}}</span></van-button>
          </div>
      </div>
      <div class="centerPanel" style="margin-top: 20px">
        <van-button class="gradient-btn" @click="verifySubmit">{{ $t('sell.label[9]') }}</van-button>
      </div>
  </div>
  </div>
</template>

<script>
export default {
  name: 'BindAccount',
  components: {

  },
  props: [],
  data() {
    return {
      tabsActive: 0,
      weixinAcc: '',
      showUsdtType: false,
      isVertical:true,
      robotList:[],
      CardInfo:{},
      BankInfo:{price:null,total_number:null},
      CoinInfo:{price:0,bestPrice:0},
      columns : ['ERC20', 'TRC20', 'OMNI'],
      douyinImg: [],
      kuaishouImg: [],
      accountArr: [],
      bestPrice:0,
      priceRate:0,
      leveldata:[],
      timer:null,
      tabsList: [],
    }
  },
  computed: {
  },
  watch: {

  },
  created() {
    var that = this;
    this.$toast.loading();
    this.$Model.GetBankCardList(data=>{
      if (data.data&&data.data.length>0){
        that.CardInfo =  data.data[0];
      }
      that.usdtinfo = this.InitData.usdtinfo;
      let price = that.InitData.USDTUSDT;

      that.CoinInfo['price'] = price;
      that.$Model.GetUserInfo((data) => {
        if (data.code == 1) {
          this.userInfo = data.info;
          var pump = this.userInfo.pump;
          this.CoinInfo['bestPrice'] = price*(1+pump);
        }
        that.$Dialog.Close();
      });

    });
    this.getLatestPrice();
    // this.$Model.GetRobotTasks({},(data) => {
    //   // debugger
    //   if (data['code']==1){
    //     this.robotList = data['info']
    //   }
    // });

    this.$parent.navBarTitle = this.$t('bindAccount.default[0]')
    // this.tabsList = this.InitData.taskclasslist.filter(item=>item.bind_status==1&&item.state==1)
    // this.accountArr = this.tabsList.flatMap(item=>item.bind_type==2?[this.UserInfo[item.bind_field]?[{url:this.ApiUrl+this.UserInfo[item.bind_field]}]:[]]:(this.UserInfo[item.bind_field]||''))
  },
  mounted() {

  },
  activated() {

  },
  beforeDestroy() {
    clearInterval(this.timer);
    // console.log("clear timer done:"+this.timer);
  },
  destroyed() {

  },
  methods: {
    getLatestPrice() {
      var that = this;
      let price = that.InitData.USDTUSDT;
      setTimeout(function() {
        that.timer = setInterval(() => {
          that.$Model.GetUserInfo((data) => {
            if (data.code == 1) {
              that.userInfo = data.info;
              var pump = that.userInfo.pump;
              console.log("最新价格变化:price"+price+",比例:"+pump)
              that.CoinInfo['bestPrice'] = price*(1+pump);
              console.log("best price"+that.CoinInfo['bestPrice'])
            }
          }, 3000);
        }, 4000);
      });
    },
    setPrice(){
      var price = this.CoinInfo.bestPrice
      this.BankInfo.price = parseFloat(price).toFixed(2);
    },
    setAmount(){
      this.BankInfo.total_number = this.UserInfo.balance;
    },
    onSubmit() {
      var tt_number = this.BankInfo.total_number*1;
      var balance =this.UserInfo.balance*1;
      if (this.BankInfo.price<=0){
        this.$Dialog.Toast(this.$t('mytask2[9]'));
        return;
      } else if (tt_number<=0){
        this.$Dialog.Toast(this.$t('mytask2[7]'));
        return;
      }else if (tt_number>balance) {
        this.$Dialog.Toast(this.$t('mytask2[8]'));
        return;
      }
        this.BankInfo.name = this.CardInfo['name'];
      this.BankInfo.card_no = this.CardInfo['card_no'];
      this.BankInfo.task_type = 2;
      this.BankInfo['task_id'] = 0;
      this.BankInfo['ordertype'] = 2;

      this.$Model.SubmitTaskOrder(
              this.BankInfo,
              (data) => {
                let msg = '';
                switch (data.code){
                  case 1:
                    msg = this.$t('common3[0]');
                    break;
                  case -1:
                    msg = this.$t('mytask2[7]');
                    break;
                  case -2:
                    msg = this.$t('hall2[2]');
                    break;
                  case -3:
                    msg = this.$t('mytask2[8]');
                    break;
                  case -4:
                    msg = this.$t('mytask2[7]');
                    break;
                  case -5:
                    msg = this.$t('mytask2[10]');
                  case -6:
                    msg = this.$t('common5[5]');
                    break
                  case -7:
                    msg = this.$t('common4[7]');
                    break
                  case -8:
                    msg = this.$t('common4[8]');
                    break
                  case -9:
                    msg = this.$t('common4[9]');
                    break
                  default:
                    msg = data.code_dec;
                    break;
                }
                let duration = 2000;
                if (data.wait){
                  duration = data.wait*1000
                }
                let msgg = {
                  message: msg,
                  duration: duration
                }
                this.$toast(msgg);
                if (data.code==1){
                  this.$router.push("/taskDetail/"+data.id);
                }
              }
      );
    },
    onChangeType(obj,value){
      this.UserInfo.usdttype = value;
      this.showUsdtType = false;
    },
    verifySubmit(){
      this.$Model.TradeStatus((data) => {
        if (data['code']==1){
          let info = data['info'];
          if (info['shimingauth']==1){
            this.$router.push("/shimingauth");
            return ;
          }
          this.onSubmit();
        }
      })
    },
  }
}
</script>
<style scoped>
    .PageBox {
      overflow-y: auto;
    }
    .ScrollBox {
      padding-top: 10px;
    }
    .van-nav-bar {

    }

    .van-cell.input >>> .van-field__control{
      height: 30px;
      line-height: 30px;
      background-color:var(--cell_bg);
        border-radius: 20px;
        padding: 10px;

    }

    .van-cell>>>.van-cell__title{
        font-size: 15px;

    }
    .van-uploader>>>.van-uploader__upload,.van-uploader>>>.van-uploader__preview-image,.van-uploader>>>.van-uploader__preview{
        margin: 0;
        background-color: #f7f8fa;
        border-radius: 8px;
    }
    .header0 {
      width: 95%;
      border-radius: 10px;
      background:transparent;
        display:flex;
        font-size: 17px;
        padding: 15px;
        padding-left: 15px;
        line-height: 40px;
        margin: 0px auto;
    }

    .MyHeader2 {
        font-size: 19px;
        border-radius: 50%;
        background: pink;
        overflow: hidden;
        width: 42px;
        height: 42px;
        margin-right: 10px;
    }
    .MyHeader2 img {
        width: 100%;
    }
    .panelHeader {
        margin-top: 0px;
        border: 1px solid #cccccc;
        border-radius: 5px;
        color: #cccccc;
        width: 40%;
        text-align: center;
        margin-bottom: 10px;
    }
    .van-cell {
        background: transparent;
    }

    .PageBox .van-cell>>>.van-cell__title{
        font-size: 15px;
        font-weight: bold;
    }

    .van-cell--borderless.input >>> .van-field__control {
        color: var(--cell_title) !important;
        padding-left: 15px;
        font-size: 15px;
        background-color: var(--main-bg-color);
        border: 1px solid #404040;
    }
    .van-cell >>> .van-field__control::-webkit-input-placeholder {

        color: var(--bc-text-color-3);
    }

    .van-cell >>> .van-field__control::-moz-placeholder {

        color: var(--cell_placeholder);
    }

    .van-popup {
        background-color: #4e51bf;
    }

    .hallswipe {
        width: 96%;
        margin-left: 6px;
        padding: 15px;
        height: 60px;
        z-index: 50;
        margin-top: 5px;
        margin-bottom: 5px;
        color:#cccccc;

    }

    .robotheader{
        font-size: 19px;
        border-radius: 50%;
        background: pink;
        overflow: hidden;
        width: 32px;
        height: 32px;
    }
    .infoBox {
        margin-left: 10px;
        border-radius: 10px;
        background-color: var(--panel_color);
        padding: 10px;
        margin: 10px;
    }

    .btn {
        width: 90%;
        padding: 10px 50px;
        border-radius: 5px;
        background-color: var(--button_bg);
        color: #FFFEFF;
        font-size: 18px;
        text-align: center;
        margin: 15px 20px 30px;
    }

    .van-button {
      background: #000000;
    }
</style>
